<template>
  <div  class="form-container">
    <div class="bg-shade--gray">
      <div class="bg-orange">
        <div class="text-title --white text-center font-weight-bold pt-3 pb-3">
          {{ $t("reservationAndUsageHistory.moveOfAccommodationDate") }}
        </div>
      </div>
      <div class="px-5 my-4">
        <div class="my-2">
          宿泊日程　{{ booking.checkInDate | jaLongDate }}～{{  booking.checkOutDate | jaLongDate }}
        </div>
        <div class="date-range pb-4">
          <v-text-field flat @click="showCalendar(booking.checkInDate)" :value="newCheckInDate" label="チェックイン" hide-details="auto" readonly />
          <div class="px-2">〜</div>
          <v-text-field flat @click="showCalendar(booking.checkOutDate)" :value="newCheckOutDate" label="チェックアウト" hide-details="auto" readonly />
          <div class="pl-2">
            <v-btn
              color="primary"
              :disabled="!newCheckInDate || !newCheckOutDate || (newCheckInDate === booking.checkInDate && newCheckOutDate === booking.checkOutDate)"
              @click="stageChange"
            >
              確定
            </v-btn>
          </div>
        </div>
        <template v-if="changeStaged && calculatedResult">
          <div class="text-center text-medium --c-pink ">
            {{ $t("reservationAndUsageHistory.chargeChangeDueToMov") }}
          </div>
          <div class="text-center --c-prm pb-6 d-flex justify-center">
            <div v-if="additionalFee.jpy">
              <span class="text-max font-weight-bold">{{ additionalFee.jpy | toThousands }}</span>
              <span class="text-medium">円</span>
            </div>
            <div class="text-max mx-2 font-weight-bold" v-if="additionalFee.jpy && additionalFee.point"> / </div>
            <div v-if="additionalFee.point">
              <span class="text-max font-weight-bold">{{ additionalFee.point | toThousands }}</span>
              <span class="text-medium">ポイント</span>
            </div>
            <div class="text-medium" v-if="additionalFee.jpy === 0 && additionalFee.point === 0">追加料金/追加ポイントはございません</div>
          </div>
          <v-divider class="pb-4"></v-divider>
          <div class="d-flex justify-space-between align-center pb-4">
            <div class="--c-medium-gray">
              <span class="text-title font-weight-bold">{{ $t('reservationAndUsageHistory.totalFee') }}</span>
            </div>
            <div class="d-flex justify-center --c-prm">
              <div class="text-right" v-if="totalFeeAfterMove.jpy">
                <span class="text-huge font-weight-bold">{{ totalFeeAfterMove.jpy | toThousands }}</span>
                <span class="text-medium">円</span>
              </div>
              <div class="text-huge mx-2 font-weight-bold" v-if="totalFeeAfterMove.jpy && totalFeeAfterMove.point"> / </div>
              <div class="text-right" v-if="totalFeeAfterMove.point">
                <span class="text-huge font-weight-bold">{{ totalFeeAfterMove.point | toThousands }}</span>
                <span class="text-medium">ポイント</span>
              </div>
            </div>
          </div>

          <template v-if="hasCancel">
            <div class="text-center text-medium --c-pink ">
              {{ $t("reservationAndUsageHistory.cancelPenaltyDueToDecreasedDays") }}
            </div>

            <div class="text-center --c-prm pb-6 d-flex justify-center">
              <div v-if="totalCancelFee.jpy">
                <span class="text-max font-weight-bold">{{ totalCancelFee.jpy | toThousands }}</span>
                <span class="text-medium">円</span>
              </div>
              <div class="text-max mx-2 font-weight-bold" v-if="totalCancelFee.jpy && totalCancelFee.point"> / </div>
              <div v-if="totalCancelFee.point">
                <span class="text-max font-weight-bold">{{ totalCancelFee.point | toThousands }}</span>
                <span class="text-medium">ポイント</span>
              </div>
              <div class="text-medium" v-if="totalCancelFee.jpy === 0 && totalCancelFee.point === 0">キャンセル料/ポイントはございません</div>
            </div>
          </template>

          <div class="text-medium pb-2">{{ $t('reservationAndUsageHistory.allListedPricesIncludeTax') }}</div>
          <div v-if="hasParkingOrRentalReservation" class="parking-rental-reservation-area pb-3">
            <div class="text-medium --c-pink">
              <div class="text-title text-center --w-bold">「”ご注意 必ずお読みください”」</div>
              宿泊日延長時は、貸出品や駐車場予約は自動で延長されません。個別変更をお願い致します。
            </div>
          </div>
          <!-- <div class="parking-rental-reservation-area pb-3">
            <div v-if="hasReservaionExensionError" class="text-medium --c-pink">
              <div class="text-title text-center --w-bold">「”ご注意 必ずお読みください”」</div>
              ご利用の駐車場・貸出品で継続利用ができないものがあります。予約変更後予約情報変更ページで個別変更をお願い致します。
            </div>
            <v-checkbox
              v-if="showReservationExtensionCheck"
              v-model="extendParkingAndRental"
              :label="'駐車場・貸出品を継続して利用する'"
              :value="true"
            ></v-checkbox>
          </div> -->
        </template>
      </div>
    </div>

    <div v-if="askPrePaymentAdjustment">
      <TVPPrePaymentChangeFormForCancel
        ref="prePaymentForm"
        :valid.sync="newPrePaymentValid"
        :newAccommodationFee="totalFeeAfterMove.jpy"
        :total-penalty="totalCancelFee.jpy"
        v-model="prePaymentAndCancelTvp"
        hideCancelIfZero
      />
    </div>
    <div class="px-3">
      <v-btn
        elevation="0"
        rounded
        block
        x-large
        @click="confirm"
        :disabled="!canSave"
        class="text-title --white mb-3"
        color="btn-grad--orange"
      >
        {{ $t('reservationAndUsageHistory.save') }}
      </v-btn>
    </div>

    <ConfirmationOfAdditionalDays
      :visible="confirmDaysModal"
      reason="チェックイン／アウト日の変更による"
      :additionalFee="additionalFee.jpy"
      :cancelFee="totalCancelFee.jpy"
      :priceUnit="priceUnit"
      @close="confirmDaysModal=false"
      @confirm="moveBooking"
      showOldMessage
    />

    <RoomAvailability
      :checkIn="booking.checkInDate"
      :checkOut="booking.checkOutDate"
      :visible="isVisibleRoomAv"
      :visibleDate="roomAvDate"
      noPersistent
      @close="isVisibleRoomAv = false"
      @confirm="setNewCheckInOut"
    />
  </div>
</template>

<script>
import { selectedBookingComputed } from '@/views/Booking/selected-booking.mixin'
import { addDays } from '@/utils/get-date'
import ConfirmationOfAdditionalDays from '@/views/Booking/components/ConfirmationOfAdditionalDays.vue'
import RoomAvailability from '@/views/VacancySearch/components/RoomAvailability'
import TVPPrePaymentChangeFormForCancel from '@/views/Booking/ReservationDetails/ReservationChange/TVPPrePaymentChange/TVPPrePaymentChangeFormForCancel/index.vue'

export default {
  name: 'AccommodationDateMove',
  mixins: [selectedBookingComputed],
  components: { ConfirmationOfAdditionalDays, RoomAvailability, TVPPrePaymentChangeFormForCancel },
  watch: {
    contract: {
      immediate: true,
      handler() {
        this.$store.commit('setDateRestriction', this.contract);
      }
    },
    booking: {
      immediate: true,
      handler() {
        this.newCheckInDate = this.booking.checkInDate
        this.newCheckOutDate = this.booking.checkOutDate
      }
    },
    changeStaged (value) {
      if (value) {
        this.recalulate()
      }
    },
    showReservationExtensionCheck: {
      immediate: true,
      handler (value) {
        this.extendParkingAndRental = value
      }
    }
  },
  data () {
    return {
      calculating: false,
      calculatedResult: null,
      newPrePayment: null,
      roomAvDate: null,
      isVisibleRoomAv: false,
      extendParkingAndRental: false,
      newCheckInDate: this.booking?.checkInDate,
      newCheckOutDate: this.booking?.checkOutDate,
      changeStaged: false,
      confirmDaysModal: false,
      newPrePaymentValid: false,
      prePaymentAndCancelTvp: {}
    }
  },
  computed: {
    canSave () {
      return this.changeStaged && (
        !this.askPrePaymentAdjustment || this.newPrePaymentValid
      )
    },
    bookingMoveArgs() {
      return {
        bookingId: this.id,
        newCheckInDate: this.newCheckInDate,
        newCheckOutDate: this.newCheckOutDate,
        bookingPrePayment: this.newPrePayment,
        extendParking: false, // !!this.extendParkingAndRental, temporarily disabled. see https://kujira.backlog.com/view/TO2020-1064#comment-359936805
        extendRentalItems: false // !!this.extendParkingAndRental, temporarily disabled. see https://kujira.backlog.com/view/TO2020-1064#comment-359936805
      }
    },
    additionalFee () {
      if (!this.calculatedResult?.totalAccommodation) { return {} }

      return {
        point: this.calculatedResult.totalAccommodation.accommodationPriceSp
          + this.calculatedResult.totalAccommodation.accommodationPriceFp
          - this.currentAccommodationFee.sp
          - this.currentAccommodationFee.fp,
        jpy: this.calculatedResult.totalAccommodation.accommodationPrice - this.currentAccommodationFee.jpy,
        accommodationFeeOnly: this.calculatedResult.totalAccommodation.accommodationFeePrice - this.currentAccommodationFee.onlyAccommodationFeeJpy
      }
    },
    totalAccommodationFeeAfterMove () {
      if (!this.calculatedResult?.totalAccommodation) { return {} }
      const totals = this.calculatedResult.totalAccommodation;
      return {
        point: totals.accommodationPriceSp + totals.accommodationPriceFp,
        jpy: totals.accommodationPrice,
        accommodationFeeOnly: totals.accommodationFeePrice
      }
    },
    hasExtend () {
      return this.booking.checkInDate > this.newCheckInDate ||
        this.booking.checkOutDate < this.newCheckOutDate
    },
    hasCancel () {
      return this.booking.checkInDate < this.newCheckInDate ||
        this.booking.checkOutDate > this.newCheckOutDate
    },
    totalCancelFee () {
      if (this.calculatedResult?.totalCancelFee) {
        return {
          point: this.calculatedResult?.totalCancelFee.penaltyPriceSp + this.calculatedResult?.totalCancelFee.penaltyPriceFp,
          jpy: this.calculatedResult?.totalCancelFee.penaltyPrice
        }
      } else {
        return { point: 0, jpy: 0}
      }
    },
    totalAdditionalFee (){
      const additional = this.additionalFee;
      return {
        point: this.totalCancelFee.point + (additional.point ?? 0),
        jpy: this.totalCancelFee.jpy + (additional.jpy ?? 0)
      }
    },
    totalFeeAfterMove () {
      const accommodation = this.totalAccommodationFeeAfterMove;
      return {
        point: accommodation.point ?? 0,
        jpy: accommodation.jpy ?? 0
      }
    },
    hasReservaionExensionError () {
      return this.calculatedResult.hasReservaionExensionError
    },
    showReservationExtensionCheck () {
      return this.calculatedResult?.showReservationExtensionCheck
    },
    hasParkingOrRentalReservation () {
      return true; // always true to show the alert, but we may have to change that.
    },
    askPrePaymentAdjustment () {
      return Boolean(
        this.changeStaged && this.calculatedResult && this.canUseTvp && this.totalFeeAfterMove.jpy
      )
    }
  },
  methods: {
    confirm () {
      this.confirmDaysModal = true
    },
    stageChange () {
      this.extendParkingAndRental = false;
      this.changeStaged = true
    },
    setNewCheckInOut ({ checkInDate, checkOutDate }) {
      this.isVisibleRoomAv = false
      this.changeStaged = false
      this.newCheckInDate = checkInDate
      this.newCheckOutDate = checkOutDate
    },
    showCalendar (date) {
      this.roomAvDate = date
      this.isVisibleRoomAv = true
    },

    async moveBooking ({ password }) {
      await this.$doLoading(async () => {
        await this.$showGqlError(async () => {
          await this.$store.dispatch('moveBooking', {
            args: this.bookingMoveArgs, password,
            newPrePayment: this.prePaymentAndCancelTvp.accommodationFee,
            cancelUseTvp: this.prePaymentAndCancelTvp.cancelFee,
          })
          this.confirmDaysModal = false
          if (this.hasReservaionExensionError) {
            this.completeChange({
              message: 'ご利用の駐車場・貸出品で継続利用ができないものがあります。日程変更後、予約変更画面で個別変更をお願い致します。',
              buttons: [{
                text: '予約変更画面',
                click: () => {
                  this.completeChange({ completed: false })
                  this.$router.push({
                    name: 'home-booking-reservation-details-detail-reservation-change',
                    params: this.$route.params
                  })
                }
              }]
            })
          } else {
            if (this.hasParkingOrRentalReservation) {
              this.completeChange({
                message: '<span class="--c-pink">「ご注意ください」<br>追加予約日には駐車場・貸出品は登録されておりません。<br>駐車場・貸出品が必要な場合は別途「予約変更」より登録をお願いいたします。</span>',
                buttons: [{
                  text: '予約変更画面',
                  click: () => {
                    this.completeChange({ completed: false })
                    this.$router.push({
                      name: 'home-booking-reservation-details-detail-reservation-change',
                      params: this.$route.params
                    })
                  }
                }]
              })
            } else {
              this.completeChange();
            }
          }
        })
      })
    },
    async recalulate () {
      this.calculating = true

      try {
        await this.$doLoading(async () => {
          await this.$showGqlError(async () => {
            this.calculatedResult = await this.$store.dispatch('calculateMoveFee', {
              args: this.bookingMoveArgs,
              oldCheckInDate: this.booking.checkInDate,
              oldCheckOutDate: this.booking.checkOutDate
            })
          })
        })
      } finally {
        this.calculating = false
      }
    },
  }
}
</script>

<style scoped>
.date-range {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
