<template>
  <CommonModal
    :visible="isVisible"
    :is-persistent="true"
    @close="$emit('close')"
  >
    <v-form ref="form">
      <div class="px-5 pb-4">
      <h2 class="text-title --medium-gray text-left font-weight-bold pt-3 pb-3 ">
        {{ reason }}<br />
          <template v-if="hasAnyFee">
            {{ $t('reservationAndUsageHistory.thereIsAnAdditionalChargePoints') }}
          </template>
          <template v-else>
            {{ $t('reservationAndUsageHistory.noExtraChargePoints') }}
          </template>
      </h2>

      <template v-if="hasAdditionalFee">
        <h2 class="text-medium --prm-light text-  left font-weight-bold pt-3">
          <template v-if="showOldMessage">
            {{ $t('reservationAndUsageHistory.additionalChargesAdditionalPointsDueToAdditionalDays') }}
          </template>
          <template v-else>
            {{ $t('reservationAndUsageHistory.additionalCharge') }}
          </template>
        </h2>
        <div class="text-left pb-5">
          <span class="text-large--szm --prm-light font-weight-bold">
            {{ additionalFee | toThousands }}
          </span>
          <span class="text-medium --prm-light font-weight-bold">
            {{ priceUnit }}
          </span>
        </div>
      </template>
      <template v-if="hasCancelFee">
        <h2 class="text-medium --prm-light text-left font-weight-bold pt-3">
          {{ $t('text.cancelChargePt') }}
        </h2>
        <div class="text-left pb-5">
          <span class="text-large--szm --prm-light font-weight-bold">
            {{ cancelFee | toThousands }}
          </span>
          <span class="text-medium --prm-light font-weight-bold">
            {{ priceUnit }}
          </span>
        </div>
      </template>

      <div class="text-medium --c-bg text-left pb-6 pl-1">
        {{ $t('reservationAndUsageHistory.loginPasswordForConfirmation') }}<br />
        {{ $t('reservationAndUsageHistory.pleaseEnter') }}
      </div>

      <PasswordInput
        v-model="password"
        class="mb-6 rounded-lg"
      />

      <div class="px-5">
        <v-btn
          elevation="0"
          rounded
          :disabled="!password"
          x-large
          block
          @click="confirm"
          class="text-medium --white font-weight-bold mb-3"
          color="btn-grad--prm"
        >
          {{ $t("reservationAndUsageHistory.reservationChange") }}
        </v-btn>
        <v-btn
          elevation="0"
          rounded
          x-large
          block
          outlined
          @click="back"
          class="text-medium bg-white --prm-light font-weight-bold mb-3"
        >
          {{ $t("reservationAndUsageHistory.return") }}
        </v-btn>
      </div>
    </div>
    </v-form>
  </CommonModal>
</template>

<script>
import CommonModal from '@/components/Modal/Common'
import PasswordInput from '../../../components/Inputs/PasswordInput.vue'

export default {
  name: 'ConfirmationOfAdditionalDays',
  components: {
    CommonModal,
    PasswordInput
  },
  props: {
    reason: {
      type: String
    },
    visible: {
      Boolean,
      default: false
    },
    priceUnit: String,
    additionalFee: Number,
    cancelFee: Number,
    showOldMessage: Boolean,
  },
  data () {
    return {
      password: null
    }
  },
  computed: {
    hasAdditionalFee () {
      return this.additionalFee > 0
    },
    hasCancelFee () {
      return this.cancelFee > 0
    },
    hasAnyFee () {
      return this.hasAdditionalFee || this.hasCancelFee
    },
    isVisible: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    }
  },
  methods: {
    confirm () {
      if (this.$refs.form.validate()) {
        this.$emit('confirm', {
          password: this.password
        })
      }
    },
    back () {
      this.$emit('close')
    }

  }
}
</script>
